import React from "react"
import { Link } from "gatsby"
import SanityImage from "gatsby-plugin-sanity-image"
import SocialShare from "./SocialShare"
import { nonBreakable } from "../constants/helpers"

export default function ArticleHeader({
  titleRu,
  titleFi,
  authorName,
  publishDate,
  sectionData,
  issueData,
  topicData,
  cover,
}) {
  //Section
  let sectionTitleRu = ""
  let sectionTitleFi = ""
  let sectionSlug = "/"
  if (sectionData) {
    sectionTitleRu = sectionData.title.ru
    sectionTitleFi = sectionData.title.fi
    sectionSlug += sectionData.slug.current
  }
  //Topic
  let topicTitleRu = ""
  let topicTitleFi = ""
  let topicSlug = "/"
  if (topicData) {
    topicTitleRu = topicData.title.ru
    topicTitleFi = topicData.title.fi
    topicSlug += topicData.slug.current
  }
  //Date
  let localDate = new Date(publishDate)
  const ruDate = localDate.toLocaleString("ru-RU", {
    day: "numeric",
    month: "long",
  })
  const fiDate = localDate.toLocaleString("fi-FI", {
    month: "long",
    year: "numeric",
  })
  const renderedDate = `${ruDate} ${fiDate}`
  //Title style
  let titleClass = "article-title"
  //Cover
  let coverImage = null
  let coverAlt = "Kuva"
  let captionElement = null
  let coverCopyrightUrl = ""
  let headerClass = "article-header"
  let coverWidth = 1280
  let coverHeight = 720
  if (cover.isVertical) {
    headerClass += " vertical"
    coverWidth = 640
    coverHeight = null
  }
  if ((titleRu.length > 40 && cover.isVertical) || titleRu.length > 64) {
    titleClass = "long-title"
  }
  titleRu.split(" ").forEach(word => {
    if (word.length >= 14) {
      titleClass += " hyphen"
    }
  })
  if (cover.asset) {
    coverImage = cover.asset
    coverAlt = cover.alt ? cover.alt : `${titleRu} / ${titleFi}`
    captionElement = cover.caption ? (
      <>
        {cover.caption.split(" / ").map((line, i) => (
          <span key={i}>{line}</span>
        ))}
      </>
    ) : (
      captionElement
    )
    coverCopyrightUrl = cover.copyrightUrl ? cover.copyrightUrl : ""
  }

  return (
    <section className={headerClass}>
      <div className="article-info">
        <div className="directory-block">
          {sectionData && (
            <p className="directory-block__section section-caption">
              <Link to={sectionSlug}>
                <span className="russian">{sectionTitleRu}</span>
                <span className="finnish">{sectionTitleFi}</span>
              </Link>
            </p>
          )}
          {topicData && (
            <span className="directory-block__divider section-caption">{`>`}</span>
          )}
          {topicData && (
            <p className="directory-block__topic section-caption">
              <Link to={topicSlug}>
                <span className="russian">{topicTitleRu}</span>
                <span className="finnish">{topicTitleFi}</span>
              </Link>
            </p>
          )}
        </div>
        <h1 className={titleClass}>
          <span className="russian" lang="ru">
            {nonBreakable(titleRu)}
          </span>
          {titleRu !== titleFi && (
            <span className="finnish" lang="fi">
              {nonBreakable(titleFi)}
            </span>
          )}
        </h1>
        <div className="details-block">
          <div className="data-block">
            <span className="data-block__author name-caption">
              {authorName}
            </span>
            <span className="data-block__date date-caption">
              {renderedDate}
            </span>
          </div>
          {/* SHARE */}
          <SocialShare />
        </div>
      </div>
      {cover.asset && (
        <div className="article-cover image-block">
          <SanityImage
            asset={coverImage}
            alt={coverAlt}
            width={coverWidth}
            height={coverHeight}
          />
          {captionElement && (
            <p className="image-caption">
              {coverCopyrightUrl ? (
                <a
                  href={coverCopyrightUrl}
                  target="_blank"
                  rel="noreferrer noopener nofollow"
                >
                  {captionElement}
                </a>
              ) : (
                captionElement
              )}
            </p>
          )}
        </div>
      )}
    </section>
  )
}
