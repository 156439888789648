import React from "react"
import Listing from "./Listing"
import { shuffleOrder } from "../constants/helpers"

export default function ArticleRelated({
  id,
  tagsIDs,
  relatedTitle,
  relatedArticles,
  relatedByIssue,
  relatedBySection,
  allWithTags,
}) {
  const relatedCount = [6, 3]
  let relatedTitleRu = "Читайте также"
  let relatedTitleFi = "Lue myös"
  let relatedLinksData = []
  let uniqueIDs = new Set()
  uniqueIDs.add(id)

  //Get title if set
  if (relatedTitle?.ru && relatedTitle?.fi) {
    relatedTitleRu = relatedTitle.ru
    relatedTitleFi = relatedTitle.fi
  }

  //Get articles from Related if set
  if (relatedArticles) {
    relatedLinksData = [...relatedArticles]
    for (const article of relatedLinksData) {
      uniqueIDs.add(article._id)
    }
  }

  //Related from Issue
  if (
    relatedByIssue.edges.length > 0 &&
    relatedLinksData.length < relatedCount[0]
  ) {
    let issueArticles = []
    for (const edge of relatedByIssue.edges) {
      if (!uniqueIDs.has(edge.node._id) && edge.node.section) {
        issueArticles = [...issueArticles, edge.node]
        uniqueIDs.add(edge.node._id)
      }
    }
    issueArticles = shuffleOrder(issueArticles).slice(0, relatedCount[0] / 2)
    relatedLinksData = [...relatedLinksData, ...issueArticles]
  }

  //Related from Tags
  if (tagsIDs.length > 0 && relatedLinksData.length < relatedCount[0]) {
    let tagsArticles = []
    let filteredArticles = allWithTags.edges.filter(
      article => !uniqueIDs.has(article.node._id)
    )
    for (const article of filteredArticles) {
      if (article.node.section) {
        for (const tag of article.node.tags) {
          if (tagsIDs.includes(tag._id)) {
            tagsArticles = [...tagsArticles, article.node]
            uniqueIDs.add(article.node._id)
          }
        }
      }
    }
    tagsArticles = shuffleOrder(tagsArticles).slice(0, relatedCount[0] / 2)
    relatedLinksData = [...relatedLinksData, ...tagsArticles]
  }

  //Related from Section
  if (
    relatedBySection.edges.length > 0 &&
    relatedLinksData.length < relatedCount[0]
  ) {
    let issueSection = []
    for (const edge of relatedBySection.edges) {
      if (!uniqueIDs.has(edge.node._id) && edge.node.section) {
        issueSection = [...issueSection, edge.node]
        uniqueIDs.add(edge.node._id)
      }
    }
    issueSection = shuffleOrder(issueSection)
    relatedLinksData = [...relatedLinksData, ...issueSection]
  }

  if (relatedLinksData.length > 0) {
    return (
      <section className="related-section">
        <p className="section-caption">
          <span className="russian">{relatedTitleRu}</span>
          <span className="finnish">{relatedTitleFi}</span>
        </p>
        <Listing data={relatedLinksData} limit={relatedCount} mode="related" />
      </section>
    )
  } else {
    return <div></div>
  }
}
