import React from "react"
import { Link } from "gatsby"
import ContactLink from "./ContactLink"
import { nonBreakable } from "../constants/helpers"
import SanityImage from "gatsby-plugin-sanity-image"

export default function ArticleFooter({ authorData, tagsData }) {
    //Author
    let authorAboutRu = ""
    let authorAboutFi = ""
    // let authorPhotoUrl = ""
    let authorPhotoAlt = ""
    let showAuthor = false
    let showAuthorPhoto = false
    let photoAsset = null
    let authorLinks = []
    let authorLinkElements = []
    const imageWidth = 240
    if (authorData) {
        if (authorData.about && authorData.about.ru.length>50) {
        authorAboutRu = nonBreakable(authorData.about.ru)
        authorAboutFi = nonBreakable(authorData.about.fi)
        showAuthor = true
        }
        if (authorData.photo && authorData.photo.asset) {
        photoAsset = authorData.photo.asset
        // authorPhotoUrl = authorData.photo.asset.url
        authorPhotoAlt = authorData.photo.copyright
        showAuthorPhoto = true
        }
        if (authorData.links) {
        authorLinks = authorData.links
        //Rendering author link elements
            authorLinkElements = authorLinks.map((link, i) => (
              <ContactLink type={link.type} url={link.url} key={i} />
            ))
        }
    }
    //Tags
    //Rendering tags elements
      const tagElementsRu = tagsData.map((tag, i) => {
        const tagSlug = `/tag/${tag.slug.current}`
        return (
          <Link to={tagSlug} className="tag" key={i}>{tag.title.ru}</Link>
        )
      })
      const tagElementsFi = tagsData.map((tag, i) => {
        const tagSlug = `/tag/${tag.slug.current}`
        return (
          <Link to={tagSlug} className="tag" key={i}>{tag.title.fi}</Link>
        )
      })

  return (
    <section className="article-footer">
        {
        // Tags
        tagsData.length !== 0 && (
            <div className="tags-block">
                {tagElementsRu}
                {tagElementsFi}
            </div>
        )
        }
        {
        // Author
        showAuthor && (
            <div className="author-block">
            {showAuthorPhoto && (
                <div className="author-block__photo">
                    <SanityImage asset={photoAsset} alt={authorPhotoAlt} width={imageWidth} />
                </div>
            )}
            <div className="author-block__about">
                <span className="name-caption">{authorData.name.fi}</span>
                <div className="about">
                    <div className="links">
                    {authorLinkElements}
                    </div>
                    <div className="description">
                    <p className="paragraph-2 russian" lang="ru">{authorAboutRu}</p>    
                    <p className="paragraph-2 finnish" lang="fi">{authorAboutFi}</p>
                    </div>
                </div>
            </div>
            </div>
        )
        }
    </section>    
  )
}
